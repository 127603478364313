<template>
  <div class="pay-job-dia">

    <div class="pay-job-remind">
      <el-dialog
          :visible="isopen"
          width="500px"
          :show-close="false"
      >
        <div class="pay-job-font-div">
          <div class="pay-job-recommend">
            推荐
          </div>
          <div style="display: flex;justify-content: center;align-items: center;margin-bottom: 30px">
            <div style="border-radius:50%;width: 24px;height: 24px;background-color: #fa6a31;font-size: 16px;color: white;line-height: 24px;margin-right: 5px">￥</div>
            <div class="payjob-title">付费发布</div>
          </div>
          <p class="payjob-content">
            每天可聊：<span style="color: #FE6002;font-weight: bold">{{params.chat_num}}</span>人&nbsp;&nbsp;&nbsp;
            有效期：<span style="color: #FE6002;font-weight: bold">{{params.deadline}}</span>天
          </p>
          <p class="payjob-content">
            优惠价：<span style="color: #FE6002;font-weight: bold;font-size: 23px">{{params.price}}</span>元
          </p>
          <div class="payjob-but">
            <el-button @click.stop="closeRemindDia(false)">继续免费发布</el-button>
            <el-button type="primary" @click.stop="closeRemindDia(true)">立即开通</el-button>
          </div>
        </div>
      </el-dialog>
    </div>

<!--    第二个弹窗开放职位或者去开通会员-->
    <el-dialog
        width="500px"
        :visible.sync="isopenPayJobOrVip"
    >
      <div class="two-payjob-div">
        <div class="two-payjob-header">
          <div class="payjob-left" :class="payChoose === 'payjob' ? 'payjob-div-choose' : ''"
               @click.stop="toPayChoose('payjob')">
            <p class="payjob-content-title">开放职位</p>
            <p style="font-size: 12px;font-weight: normal">更加快捷 30天</p>
          </div>
          <div class="payjob-right" :class="payChoose === 'payVip' ? 'payjob-div-choose' : ''"
               @click.stop="toPayChoose('payVip')">
            <p class="payjob-content-title">VIP套餐</p>
            <p style="font-size: 12px;font-weight: normal">更加经济实惠</p>
            <div class="tui-jian-vips">推荐</div>
          </div>
        </div>
        <div style="height: 1px;background-color: #d9d9d9;margin: 10px 0"></div>
        <div v-show="payChoose === 'payjob'">
          <div class="two-payjob-jobparams">
            <div style="margin-left: 20px">
              <p style="font-size: 16px;color: #191919;margin-bottom: 10px;font-weight: bold">{{params.job_name}}</p>
              <p style="font-size: 12px;color: #FE6002">享受开放职位30天，直聊权益30人/天</p>
            </div>
            <div style="margin-right: 20px">
              <span>￥</span><span style="color: #FE6002;font-size: 17px;font-weight: bold">{{params.price}}</span> 元
            </div>
          </div>
          <div style="margin-top: 20px">
            <p style="text-align: left;font-size: 16px">购买说明：</p><br>
            <p style="text-align: left;line-height: 20px;font-size: 12px">
              1、开放职位获得直聊权益，招聘者可以主动给求职者发起聊天。<br>
              2、猎宠网会对您发布的职位进行审核，请留意审核结果。职位未通过审核的，您支付的费用会在72小时内原路退回至您的账户。<br>
              3、职位最终价格根据购买档位、优惠幅度、地区、职类等因素产生波动，具体金额以购买前平台显示为准。<br>
              4、该产品属于数字化产品，不支持七天无理由退款。<br>
              5、开放职位购买后立即生效，职位中包含的权益仅限在当前职位使用，有效期每月按30个自然日计算。<br>
              6、升级VIP套餐更划算，详询:021-60409917，也可直接咨询在线客服<br>
            </p>
          </div>
        </div>

        <div v-show="payChoose === 'payVip'">
          <p style="font-size: 17px;color: #191919;margin: 20px 0;font-weight: bold">套餐选择：</p>
          <div
              v-for="item in vipList" :key="item.id"
              class="vip-dia-list"
          >
            <div style="padding: 10px 0 0 0;display: flex">
              <div>
                <img src="@/assets/releasejob_vip_img.png" style="height: 25px;width: auto;margin-right: 10px;margin-left: 10px">
              </div>
              <div>
                <span style="font-size: 17px;color: #191919;display: inline-block;margin-bottom: 8px;font-weight: bold">{{ item.name }}</span>
                <span style="display: flex;flex-wrap: wrap">
                  <div v-for="(k,v) in item.arr" :key="v" style="margin-right: 15px;margin-bottom: 6px">
                    {{ k.title }}：<span style="font-weight: bold">{{ k.num }}</span>
                  </div>
                </span>
              </div>
            </div>
            <div style="margin-top: 15px;padding-left: 49px;display: flex;justify-content: space-between">
              <div>
                <span>￥</span>
                <span style="font-weight: bold;font-size: 17px;color: #FE6002">{{item.service_price}}</span> /
                <span>{{item.service_time}}</span>天
              </div>
              <div >
<!--                购买会员-->
                <el-button type="primary" @click.stop="toPayVip(item)">立即开通</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="two-payjob-foot" v-show="payChoose === 'payjob'">
        <div>
          <span style="font-size: 17px;font-weight: bold;color: #191919;margin-left: 25px;">支付金额: </span>
          <span style="color: #FE6002;font-size: 17px;font-weight: bold"><span style="font-size: 12px">￥</span>{{params.price}} </span>
          <span>元/月</span>
        </div>
        <div style="margin-right: 25px" class="two-paybutton-ok">
<!--          开放职位-->
          <el-button type="primary" @click.stop="toPayJob()">立即开通</el-button>
        </div>
      </div>
      <div style="height: 1px"></div>
    </el-dialog>

    <el-dialog
        width="400px"
        :title="payDialogTitle"
        @close="closeDiaBase('pay')"
        :visible="isOpenPayDia">
      <div class="pay-inf-view">
        <div v-show="payChoose === 'payVip'">
          <div class="vip-type-view">
            <img class="vip-type-bg" src="@/assets/vip_pay_bg_img.png" alt="" />
            <div class="vip-type-text-view">
              <img class="lczx-img" src="@/assets/vip_pay_zx_img.png" alt="" />
              <div class="left-text-view">
                <div class="name-tt1">{{ vipParams.name }}</div>
                <div class="time">/{{ vipParams.service_time }}天</div>
              </div>
              <div class="right-text-view">
                <label>￥</label>{{ vipParams.service_price }}
              </div>
            </div>
          </div>
        </div>
        <div class="pay-type-view">
          <div class="paytype-title">支付方式</div>
          <div class="paytype">
            <div
                class="paytype-item"
                @click="changePayType('wxpay')"
                :class="payType == 'wxpay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_wx_img.png" alt="" />
              <span>微信支付</span>
            </div>
            <div
                class="paytype-item"
                @click="changePayType('alipay')"
                :class="payType == 'alipay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
        <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
          <div class="wxpay-tt1">
            请使用<label>微信</label>扫描二维码完成支付
          </div>
          <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
          <div class="wxpay-tt2" @click="openAgreementFun">
            购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
          </div>
        </div>
        <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
          <el-button type="primary" @click="toAliPayPage">去支付</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        width="800px"
        title="开放职位"
        :visible.sync="isOpenAgreement"
        @close="closeAgreement"
    >
      <div v-html="vipServe" class="vipServe-content"></div>
    </el-dialog>
  </div>
</template>

<script>

import {getUserProfile} from "@/api/commen/login";
import {submitOrder} from "@/api/commen/payApi";
import RequestByNotLoading from "@/utils/requestByNotLoading";
import {getVipComboList} from "@/api/compe/aboutVipApi";

export default {
  name: "payJobDia",
  components: {},
  props: ['isopen','params','jobType','jobId'],
  data() {
    return {
      isOpenDia:false,
      isOpenPayDia:false,
      wxPayImgUrl: "",
      payType: "wxpay",
      aliNatoUrl:'',
      vipServe:"",
      isOpenAgreement: false,
      setIntervalObj:'',
      isopenPayJobOrVip:false,
      leftImg:'',
      rightImg:'',
      payChoose:'payjob',//payVip
      vipList:[],
      payDialogTitle:'开放职位',
      vipParams:{
        name:'',
        service_time:'',
        service_price:'',
        rating_id:'',
      },
    };
  },
  mounted() {

  },
  beforeDestroy() {
    clearInterval(this.setIntervalObj);
  },
  methods: {
    toPayChoose(chaoose){
      this.payChoose = chaoose;
      if (chaoose === 'payVip'){
        getVipComboList({pagepath:'payjob'}).then(e=>{
          console.log(e)
          if (e.data.list){
            e.data.list.forEach((k)=>{
              let tempVipParams = [];
              k.arr.forEach(e=>{
                if (e.num.charAt(0) !== '0'){
                  tempVipParams.push(e)
                }
              });
              k.arr = tempVipParams;
            });
          }
          this.vipList = e.data.list;
        }).catch();
      }
    },
    closeDiaBase(from){
      if (from === 'pay'){
        clearInterval(this.setIntervalObj);
        this.isOpenPayDia = false;
      }
    },
    createOrder(){
      let payobj = '';
      if (this.payChoose === 'payjob'){
        payobj = {
          pay_type: this.payType,
          type: 32,
          job_id: this.params.job_id,
          rating_id: "0",
          job_type: this.params.job_type,
        };
      }else {
        payobj = {
          pay_type: this.payType,
          type: 1,
          rating_id: this.vipParams.rating_id,
        };
      }
      submitOrder(payobj).then(e=>{
        if (this.payType === 'wxpay'){
          this.wxPayImgUrl = e.data.pay_img;
        }else {
          this.aliNatoUrl = e.data.url;
        }
        this.setTimeFor(e.data.order_id);
      });
    },
    setTimeFor(orderId){
      this.setIntervalObj = setInterval( () => {
        RequestByNotLoading({
          url: 'v2/Rating/orderStatus',
          method: 'post',
          data: {
            orderId:orderId
          },
        }).then(e=>{
          if (e.msg === '查询成功' && e.data.is_success === '2'){
            clearInterval(this.setIntervalObj);
            this.$message.success('支付成功');
            this.isOpenPayDia = false;
            this.isopenPayJobOrVip = false;
            this.$emit('payJobChang',true)
          }
        });
      },2000);
    },
    openAgreementFun() {
      console.log("打开vip协议");
      if (!this.vipServe) {
        getUserProfile("/vipServe").then((res) => {
          this.vipServe = res.data;
          this.isOpenAgreement = true;
        });
      } else {
        this.isOpenAgreement = true;
      }
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },
    changePayType(type) {
      this.payType = type;
      clearInterval(this.setIntervalObj);
      this.createOrder();
    },
    // 关闭协议弹窗
    closeAgreement(){
      console.log('关闭协议弹窗')
      this.isOpenAgreement = false;
    },
    // 关闭支付弹窗
    closePayDia(){
      console.log('关闭支付弹窗')
      this.isOpenPayDia = false;
    },
    //购买VIP
    toPayVip(item){
      console.log(item)
      this.vipParams.name = item.name;
      this.vipParams.service_time = item.service_time;
      this.vipParams.service_price = item.service_price;
      this.vipParams.rating_id = item.id;
      let payobj = {
        pay_type: this.payType,
        type: 1,
        rating_id: item.id
      };
      submitOrder(payobj).then(e=>{
        console.log(e);
        this.payDialogTitle = '购买VIP';
        this.isOpenPayDia = true;
        if (this.payType === 'wxpay'){
          this.wxPayImgUrl = e.data.pay_img;
        }else {
          this.aliNatoUrl = e.data.url;
        }
        this.setTimeFor(e.data.order_id);
      });
    },
    //购买职位
    toPayJob(){
      this.createOrder();
      this.isOpenPayDia = true;
      this.payDialogTitle = '开放职位';
    },
    // 关闭发布时的付费提醒弹窗
    closeRemindDia(e){
      console.log('关闭发布时的付费提醒弹窗')
      if (e){
        this.isopenPayJobOrVip = true;
      }else {
        clearInterval(this.setIntervalObj);
        this.$emit('payJobChang',e,1)
      }
    }
  }
};
</script>
<style scoped>
>>>.el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}
>>>.el-dialog .el-dialog__body{
  border-radius: 15px;
}
.payjob-title{
  font-size: 30px;
  color: #3b3b3b;
  font-weight: bold;
}
.payjob-content{
  font-size: 20px;
  color: #3b3b3b;
  margin-bottom: 20px;
}
>>>.payjob-but button.el-button.el-button--primary,.two-payjob-foot button.el-button.el-button--primary{
  width: 160px;
  height: 45px;
  line-height: 22px;
  font-size: 18px;
  margin-left: 20px;
  background-color: #533f1f;
  border: none;
  color: #e1caa5;
}
>>> .payjob-but button.el-button.el-button--default {
  width: 160px;
  height: 45px;
  line-height: 22px;
  font-size: 18px;
  background-color: hsla(0, 0%, 100%, 0); /* 完全透明 */
  border: 1px solid #533f1f;
  color: #533f1f;
}
>>> .vip-dia-list button.el-button.el-button--primary{
  width: 90px;
  height: 35px;
  font-size: 14px;
  margin-right: 10px;
  background-color: #533f1f;
  border: none;
  color: #e1caa5;
  border-radius: 18px;
}
.payjob-but{
  margin-top: 20px;
  margin-bottom: 30px;
}
.pay-job-dia .vipServe-content {
  border-top: 2px solid rgba(230, 230, 230, 0.5);
  padding: 50px 50px 80px;
}
.pay-job-view .payjob .item-sl{
  border: 1px solid #FE6002;
  background-color: #fffaf7;
}
.pay-job-view{
  padding: 20px 40px;
}
.payjob{
  display: flex;
  justify-content: center;
}
.payjob-item{
  width: 166px;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid#CCCCCC;
  border-radius: 4px;
  margin-right: 48px;
  cursor: pointer;
}
.payjob-title-sl{
  font-size: 16px;
  font-weight: 600;
  color: #191919;
  line-height: 19px;
}
.pay-job-font-div{
  border-radius: 15px;
  background: linear-gradient(to top right, #fbf9e8, #fde2bb);
  padding: 50px 0px 20px 0px;
  text-align: center
}
>>> .pay-job-remind .el-dialog .el-dialog__header{
  padding: 0;
}
.pay-job-recommend{
  background-color: #fa6a31;
  width: 70px;
  height: 40px;
  line-height: 40px;
  color: white;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 13px 0 13px;
}
.two-payjob-header{
  margin-top: 15px;
  display: flex;
  /*justify-content: center;*/
  font-size: 26px;
  text-align: center;
  /*border-bottom: 1px solid #FE6002;*/
  /*overflow: hidden;*/
}
.two-payjob-header > div{
  width: 100%;
  height: 70px;
  line-height: 25px;
  cursor: pointer;
  background-color: #f5f6fa;
  /*border-radius: 6px;*/
  /*background-color: rgba(255, 129, 54, 0.15);*/
}
.two-payjob-jobparams{
  height: 80px;
  /*background-color: white;*/
  background-color: #F5F6FA;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.two-payjob-div{
  padding: 0 40px;
}
/*>>>.el-dialog{
  background-color: #f5f6fa;
}*/
.two-payjob-foot{
  height: 80px;
  background-color: white;
  margin: 30px 0 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pay-job-dia .payjob-div-choose{
  background-color: #ffe4be;
  font-weight: bold;
  color: #191919;
}
.payjob-content-title{
  font-size: 20px;
  margin: 10px 0 5px 0;
}
.two-paybutton-ok{

}
.vip-dia-list{
  /*background-color: white;*/
  background-color: #f7f8fa;
  margin-bottom: 10px;
  border-radius: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.vip-dia-list:hover{
  background-color: #fff5f0;
}
.tui-jian-vips{
  height: 25px;
  width: 45px;
  color: white;
  border-radius: 0 0 0 8px;
  text-align: center;
  line-height: 25px;
  position: absolute;
  right: 40px;
  top: 45px;
  background-color: #FE6002;
  font-size: 13px;
}
</style>