<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-19 14:20:38
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-27 19:25:46
 * @FilePath: /lcwpcvue/src/views/findTalentsList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="find-talentsList">
    <div class="top-zk-div"></div>
    <div class="find-job-sear-view">
      <searview
        :pagescrollhe="pageScrollHe"
        :searchpage="'talent'"
        @toSearch="searchFun"
        @hotJobs="hotJobs"
        :slMrValF="className"
      ></searview>
      <filterateview
        :searchpage="'talent'"
        @toFliterList="fliterTalentList"
        @clearFiltrateFun="clearFiltrateFun"
        :jobClass="searchJob"
        ref="filterateview_ref"
      ></filterateview>
    </div>

    <div class="min-userList-login-tj-view">
      <div class="user-fil-navbar-view">
        <div class="nav-left">
          <div
            class="navbar-item"
            :class="userFilterData.shows === 'all' ? 'navbar-item-sl' : ''"
            @click="cutNav('all')"
          >
            <span>推荐人才</span>
            <div v-show="userFilterData.shows === 'all'" class="btm-xian"></div>
          </div>
          <div
            class="navbar-item"
            :class="userFilterData.shows === 'show' ? 'navbar-item-sl' : ''"
            @click="cutNav('show')"
          >
            <span>有作品</span>
            <div
              v-show="userFilterData.shows === 'show'"
              class="btm-xian"
            ></div>
          </div>
        </div>
        <!--        <div class="nav-right">
          <div class="px-item">
            <span>推荐职位</span>
            <i class="el-icon-sort-down"></i>
            <i class="el-icon-sort-up"></i>
          </div>
          <div class="px-item">
            <span>发布时间</span>
            <i class="el-icon-sort-down"></i>
            <i class="el-icon-sort-up"></i>
          </div>
        </div>-->
      </div>

      <div class="userlist-login-view">
        <div class="user-card-view">
          <div>
            <usercard
              :userList="userList"
              @collectionUser="collectionUser"
            ></usercard>

            <div class="user-no-login-view" v-show="!isLogin">
              <div class="list-none-login-ttt1">
                成为企业会员，高效挑选人才！
              </div>
              <div class="list-none-login-ttt2">
                登录或注册后可以查看更多简历信息~
              </div>
              <img class="ewm-img" src="@/assets/dl_ewm.png" alt="" />
              <div class="list-none-login-ttt2">扫一扫，让招聘更轻松</div>
              <div class="min-btn-view">
                <el-button type="primary" @click="toLoginFun"
                  >登录账号</el-button
                >
                <el-button type="primary" plain @click="toRegistFun"
                  >注册会员</el-button
                >
              </div>
              <div class="list-none-login-ttt3">
                如有需要任何贴心的服务：<span>021-60409917</span>
              </div>
            </div>
          </div>
          <el-pagination
            @current-change="pagesChage"
            background
            layout="prev, pager, next"
            :page-count="allPage"
            class="pag-ina"
            :current-page.sync="userFilterData.page"
          >
          </el-pagination>
        </div>
        <div class="findjoblist-login-view">
          <loginview v-show="!isLogin" :pathname="'talent'"></loginview>
          <img class="yhjl-view" src="@/assets/findjob_yh_jl.png" alt="" />

          <div class="findtalents-tjtalen-view">
            <div class="tj-title-view">
              <img class="tj-title-img" src="../assets/index_jxgw.png" alt="" />
              <span class="tj-view">推荐人才</span>
            </div>
            <div class="tj-list">
              <div
                class="tjitem"
                v-for="item in recUserList"
                :key="item.id"
                @click.stop="toRecDetail(item.uid)"
              >
                <div class="tj-top-view">
                  <span class="tj-t1"
                    >{{ item.name }}
                    <img
                      v-show="item.sex_name == '男'"
                      class="rec-gender-img"
                      src="@/assets/gender_nan.png"
                      alt=""
                    />
                    <img
                      v-show="item.sex_name == '女'"
                      class="rec-gender-img"
                      src="@/assets/gender_nv.png"
                      alt=""
                    />
                  </span>
                  <span
                    style="
                      width: 200px;
                      font-size: 12px;
                      color: #757575;
                      text-align: end;
                    "
                    >{{ item.city_class }}</span
                  >
                </div>
                <div class="age-edu-exp">
                  <span>{{ item.age }}</span>
                  <span v-show="item.edu_name">
                    <el-divider direction="vertical"></el-divider>
                    <span>{{ item.edu_name }}</span>
                  </span>
                  <span v-show="item.exp_name">
                    <el-divider direction="vertical"></el-divider>
                    <span>{{ item.exp_name }}</span>
                  </span>
                </div>
                <div>
                  <span style="color: #757575; font-size: 12px">想找：</span>
                  <span style="color: #fe6002; font-size: 12px">{{
                    item.rec_job_str
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import searview from "@/components/homeSearComp.vue";
import filterateview from "@/components/filtrateComp.vue";
import usercard from "@/components/individualUser/userCardInFindI.vue";
import loginview from "@/components/loginWthiPage.vue";
import { getTalentsList, postCollection } from "@/api/commen/aboutTalentsApi";
import { turnoverTime } from "@/utils/timeUrils";
// import myStorage from "@/utils/myStorage";
export default {
  name: "findtalentslist",
  components: {
    searview,
    filterateview,
    usercard,
    loginview,
  },
  data() {
    return {
      userFilterData: {
        page: 1, // 页码
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "all", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        job_type_id: "all",
        sex: "all",
        shows: "all",
        parent_job_id: "all",
      },
      recUserList: [],
      allPage: 100,
      userList: [],
      pageScrollHe: 0,
      isLogin: false,
      searchJob: {
        job_id: "all",
        jobclass_id: "all",
      },
      className:'职位类型',
    };
  },
  created() {
    this.isLogin = localStorage.token ? true : false;
    if (this.$route.query.kw) {
      this.userFilterData.search_key = this.$route.query.kw;
    }
    if (this.$route.query.pid) {
      setTimeout(() => {
        this.hotJobs({
          id: this.$route.query.cid,
          keyid: this.$route.query.pid,
          name:this.$route.query.name
        });
      }, 500);

      // this.searchJob.job_id = this.$route.query.pid;
      // this.searchJob.jobclass_id = this.$route.query.cid;
      // this.userFilterData.job_type_id =
      //   this.$route.query.cid === "all"
      //     ? this.$route.query.pid
      //     : this.$route.query.cid;
    }
    this.getTalentsList();
    this.getRecTalentsList(); //推荐人才
    window.addEventListener("scroll", this.homePageScroll);
  },
  methods: {
    cutNav(type) {
      this.userFilterData.shows = type;
      this.getTalentsList();
    },
    toRecDetail(id) {
      if (!localStorage.token) {
        this.$router.push("/login");
        return;
      }
      let newRouter = this.$router.resolve({
        path: `/talentsdetail?id=${id}`,
      });
      window.open(newRouter.href, "_blank");
    },
    //监听页面滚动
    homePageScroll() {
      // console.log(e);
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      // let scrollStep = scrollTop - this.oldScrollTop;
      this.pageScrollHe = scrollTop;
      // console.log("findTalentsList 滚动距离 ", scrollTop);
    },
    hotJobs(item) {
      console.log('hotJobs:',item);
      this.className = item.name
      if (item.keyid == 0) {
        console.log('hotJobs->keyid->0')
        this.userFilterData.parent_job_id = item.id;
        // this.userFilterData.job_type_id = "all";
        this.userFilterData.job_type_id = item.id;
        this.$refs.filterateview_ref.idxJobs(item.id,'all');
      } else {
        this.userFilterData.parent_job_id = item.keyid;
        this.userFilterData.job_type_id = item.id;
        this.$refs.filterateview_ref.idxJobs(item.keyid,item.id);
      }

      this.getTalentsList();
    },
    getRecTalentsList() {
      let c_arr = {
        ...this.userFilterData,
        is_precise: 1,
      };
      getTalentsList(c_arr)
        .then((result) => {
          this.recUserList = result.data.jobs;
          this.recUserList.forEach((res) => {
            res.jobOnline = turnoverTime(res.lastupdate);
            if (res.job_class) {
              res.rec_job_str = res.job_class.join(",");
            } else {
              res.rec_job_str = "";
            }
            if (res.city_class) {
              res.city_class = res.city_class.join(",");
            } else {
              res.city_class = "";
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTalentsList() {
      getTalentsList(this.userFilterData)
        .then((result) => {
          this.userList = result.data.jobs;
          this.userList.forEach((res) => {
            res.jobOnline = turnoverTime(res.lastupdate);
          });
          this.allPage = parseInt(result.data.allpage);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    pagesChage(e) {
      console.log("chage", e);
      if (!this.isLogin) {
        this.$store.dispatch("user/setUserType", 2);
        this.$cookies.set("usertype", 2);
        localStorage.setItem("usertype", 2);
        this.$router.push(`/login`);
        return false;
      }
      this.userFilterData.page = e;
      window.scrollTo(0, 0);
      this.getTalentsList();
    },

    toLoginFun() {
      this.$store.dispatch("user/setUserType", 2);
      this.$cookies.set("usertype", 2);
      localStorage.setItem("usertype", 2);
      this.$router.push(`/login`);
    },

    toRegistFun() {
      this.$store.dispatch("user/setUserType", 2);
      this.$cookies.set("usertype", 2);
      localStorage.setItem("usertype", 2);
      this.$router.push(`/registbyphone`);
    },

    searchFun(keyword) {
      console.log(keyword);
      this.userFilterData.search_key = keyword;
      this.getTalentsList();
    },
    fliterTalentList(type, id) {
      this.userFilterData.page = 1;
      if (type === "zw") {
        this.className = '职位类型';
        this.userFilterData.job_id = id.parent;
        this.userFilterData.job_type_id = id.son === "all" ? id.parent : id.son;
      } else if (type === "pro") {
        if (id.proId === "all") {
          this.userFilterData.provinceid = "all";
          this.userFilterData.cityid = "all";
        } else {
          this.userFilterData.provinceid = id.proId;
          this.userFilterData.cityid = id.cityId;
        }
      } else if (type === "sal") {
        this.userFilterData.min_value = id.min_value;
        this.userFilterData.max_value = id.max_value;
      } else if (type === "edu") {
        this.userFilterData.edu = id;
      } else if (type === "sex") {
        console.log(id);
        this.userFilterData.sex = id;
      } else if (type === "exp") {
        this.userFilterData.exp = id;
      }
      this.getTalentsList();
    },

    clearFiltrateFun() {
      this.userFilterData = {
        page: 1, // 页码
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        job_id: "all", // 职业, 例: 宠物医生
        search_key: this.userFilterData.search_key, // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        job_type_id: "all",
        sex: "all",
      };
      this.getTalentsList();
    },

    collectionUser(uid) {
      postCollection({
        uid: uid,
      })
        .then((result) => {
          console.log("收藏简历", result);
          if (result.code == 200) {
            this.$message({
              message: result.msg,
              type: "success",
              center: true,
            });
            this.getTalentsList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.find-talentsList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
body {
  padding: 0;
  margin: 0;
}

.find-job-sear-view {
  width: 1800px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.min-userList-login-tj-view {
  /* margin: 0 220px; */
}
.user-fil-navbar-view {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  margin-top: 41px;
  font-size: 22px;

  font-weight: 400;
  color: #757575;
  padding: 15px 0;
  border-radius: 8px;
  border-bottom: 2px solid#EAECF5;
}
.user-fil-navbar-view .nav-left {
  display: flex;
  align-items: center;
}

.user-fil-navbar-view .nav-left .navbar-item {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.user-fil-navbar-view .nav-left .navbar-item-sl {
  font-size: 24px;

  font-weight: 600;
  color: #fe6002;
}
.user-fil-navbar-view .nav-left .btm-xian {
  width: 40px;
  height: 3px;
  background-color: #fe6002;
  position: absolute;
  bottom: -15px;
}

.user-fil-navbar-view .nav-right {
  display: flex;
  align-items: center;
  width: 240px;
}
.user-fil-navbar-view .nav-right .px-item {
  margin-left: 20px;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
}

.user-fil-navbar-view .nav-right .px-item i {
  margin-right: -8px;
}

.userlist-login-view {
  display: flex;
}

.userlist-login-view .user-card-view {
  width: 1034px;
  text-align: center;
  position: relative;
}

.userlist-login-view .tj-title-view .tj-title-img {
  width: 26px;
  height: 26px;
}

.pag-ina {
  margin-top: 30px;
}

.findtalents-tjtalen-view {
  width: 355px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 25px 24px;
}
.findtalents-tjtalen-view .tj-title-view {
  display: flex;
  align-items: center;
}
.findtalents-tjtalen-view .tj-title-view .tj-title-img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.findtalents-tjtalen-view .tj-title-view .tj-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.findtalents-tjtalen-view .tj-list .tjitem {
  border-bottom: 1px solid #f5f6fa;
  padding: 20px 0;
  border-radius: 10px;
  cursor: pointer;
}
.findtalents-tjtalen-view .tj-list .tjitem .tj-top-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.findtalents-tjtalen-view .tj-list .tjitem .tj-top-view .tj-t1 {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

/* .findtalents-tjtalen-view .tj-list .tjitem .tj-top-view .spar-gz {
  font-size: 16px;
  
  font-weight: 500;
  color: #fe6002;
  line-height: 19px;
} */

.findtalents-tjtalen-view .tj-list .tjitem .age-edu-exp {
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin: 8px 0;
}

.findtalents-tjtalen-view .tj-list .tjitem .com-name {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-top: 8px;
}
.find-talentsList .rec-gender-img {
  width: 19px;
  height: 19px;
}
.find-talentsList .tj-list .tjitem:hover {
  background-color: #fff5f0;
  /*border: 1px solid #fe6002;*/
}

.user-card-view .user-no-login-view {
  width: 1034px;
  height: 2000px;
  text-align: center;
  position: absolute;
  top: 400px;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(3px);
}

.user-card-view .user-no-login-view .list-none-login-ttt1 {
  font-size: 35px;
  font-weight: 800;
  margin-top: 150px;
  margin-bottom: 30px;
}

.user-card-view .user-no-login-view .list-none-login-ttt2 {
  font-size: 20px;
  font-weight: 400;
  color: #999999;
}

.user-card-view .user-no-login-view .ewm-img {
  width: 180px;
  height: 180px;
  margin-top: 20px;
}

.user-card-view .user-no-login-view .min-btn-view {
  margin-top: 20px;
}
.user-card-view .user-no-login-view .min-btn-view .el-button {
  width: 150px;
  font-size: 20px;
}
.user-card-view .user-no-login-view .list-none-login-ttt3 {
  font-size: 25px;
  color: #222;
  margin-top: 30px;
}
.user-card-view .user-no-login-view .list-none-login-ttt3 span {
  color: #fe6002;
  font-size: 30px;
}
</style>