<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-05 14:57:40
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-15 09:23:56
 * @FilePath: /lcwpcvue/src/views/talentsDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="talents-detail-page">
    <div class="top-zk-div"></div>
    <div style="background-color: white; width: 100%">
      <div class="talents-baseinf-view">
        <div class="talen-lefit-view">
          <div class="avatar-name-sex-age">
            <img
              class="avatar-img"
              :src="userInfData.member.resume_photo"
              alt=""
            />
            <div class="name-sex-age-view">
              <div class="name-sex-stu">
                <span class="name">{{ userInfData.member.name }}</span>
                <img
                  class="sex-img"
                  :src="
                    userInfData.member.sex === '2'
                      ? require('@/assets/gender_nv.png')
                      : require('@/assets/gender_nan.png')
                  "
                />
                <img
                  class="rz-status-img"
                  src="@/assets/findjob_yrzimg.png"
                  alt=""
                  v-show="userInfData.member.idcard_status == 1"
                />
                <span
                  v-show="userInfData.member.idcard_status == 1"
                  class="sta-text"
                  >已实名</span
                >
              </div>
              <div class="age-edu-exp-add">
                <div class="item" v-show="userInfData.member.year_old">
                  <img
                    class="it-img"
                    src="@/assets/jobinf_age_img.png"
                    alt=""
                  />
                  <span>{{ userInfData.member.year_old }}岁</span>
                </div>
                <div class="item" v-show="userInfData.member.edu_name">
                  <img
                    class="it-img"
                    src="@/assets/jobinf_jyjl_img.png"
                    alt=""
                  />
                  <span>{{ userInfData.member.edu_name }}</span>
                </div>
                <div class="item" v-show="userInfData.member.exp_name">
                  <img
                    class="it-img"
                    src="@/assets/jobinf_gzjy_img.png"
                    alt=""
                  />
                  <span>{{ userInfData.member.exp_name }}</span>
                </div>
                <div class="item" v-show="userInfData.member.living">
                  <img
                    class="it-img"
                    src="@/assets/jobinf_addr_img.png"
                    alt=""
                  />
                  <span>{{ userInfData.member.living }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="user-tag-view">
            <div
              class="user-tag-description"
              v-show="userInfData.member.description"
            >
              {{ userInfData.member.description }}
            </div>
            <label v-for="(item, index) in userInfData.member.tag" :key="index">
              <span class="tag-item">{{ item }}</span>
            </label>
          </div>
          <div class="up-look-jb-view">
            <div class="item">
              <img src="@/assets/jobinf_uptime_img.png" alt="" />
              <span>更新于 {{ userInfData.member.lastupdate_name }}</span>
            </div>
            <div class="item">
              <img src="@/assets/jobinf_look_img.png" alt="" />
              <span>浏览 {{ userInfData.member.hits }}次</span>
            </div>
            <div class="item" @click="reportDialogShow = true">
              <img src="@/assets/jobinf_jb_img.png" alt="" />
              <span>举报</span>
            </div>
          </div>
        </div>
        <div class="right-btn-share-col-view" v-show="!isYl">
          <div class="link-btn-chat-view">
            <el-button
              v-if="userInfData.is_down == 0"
              type="primary"
              @click="lookTalentsPhone"
              plain
              >查看联系方式
            </el-button>
            <el-button
              v-if="userInfData.is_down != 0"
              type="info"
              @click="lookTalentsPhone"
              plain
              >已查看
            </el-button>
            <el-button
              type="primary"
              v-if="userInfData.is_chat == 0"
              @click="toChat"
              >立即沟通
            </el-button>
            <el-button
              type="primary"
              v-if="userInfData.is_chat != 0"
              @click="toChat"
              >继续沟通
            </el-button>
          </div>
          <div class="share-colle-view">
            <el-popover placement="bottom" width="200" trigger="click">
              <div class="share-ewm-img">
                <img :src="shareEwmImg" alt="" />
              </div>
              <div class="item" slot="reference" @click="getShareEwm">
                <img src="@/assets/jobinf_share_img.png" alt="" />
                <span>分享</span>
              </div>
            </el-popover>

            <div class="item" @click="postCollection">
              <span v-show="userInfData.is_coll === '1'">
                <i class="el-icon-star-on" style="font-size: 14px"></i>
                <span style="vertical-align: top">已收藏</span>
              </span>
              <span v-show="userInfData.is_coll === '0'">
                <i class="el-icon-star-off" style="font-size: 14px"></i>
                <span style="vertical-align: top">收藏</span>
              </span>
            </div>
          </div>
        </div>
        <div class="right-btn-share-col-view" v-show="isYl">
          <div class="link-btn-chat-view">
            <el-button type="primary" @click="toEditResPage" plain
              >编辑
            </el-button>
          </div>
          <div class="share-colle-view"></div>
        </div>
      </div>
    </div>
    <div class="talents-detailinf-tjlist-view">
      <div class="detailinf-lefit-view">
        <div class="talents-detail-commen-view">
          <div class="commen-title-view">
            <span class="title-xian"></span>
            <span class="title-text">求职意向</span>
          </div>
          <div class="qzyx-detail-view">
            <div class="yx-left-view">
              <div class="yx-item">
                <img src="@/assets/talentsdetail_qwzw_img.png" alt="" />
                <span
                  >期望职位：
                  <label
                    v-for="(item, index) in userInfData.expect.job_classid_name"
                    :key="item.id"
                  >
                    {{ item.name
                    }}<label
                      v-if="
                        index != userInfData.expect.job_classid_name.length - 1
                      "
                      >/</label
                    >
                  </label>
                </span>
              </div>
              <div class="yx-item">
                <img src="@/assets/talentsdetail_qzzt_img.png" alt="" />
                <span>求职状态：{{ userInfData.expect.report_name }}</span>
              </div>
              <div class="yx-item">
                <img src="@/assets/user_card_city.png" alt="" />
                <span
                  >期望城市：
                  <label
                    v-for="(item, index) in userInfData.expect.city_name"
                    :key="item.id"
                  >
                    {{ item.name
                    }}<label
                      v-if="index != userInfData.expect.city_name.length - 1"
                      >/</label
                    >
                  </label>
                </span>
              </div>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="yx-right-view">
              <div class="xz-item">
                <span class="dian"></span>
                <span
                  v-if="
                    userInfData.expect.minsalary != 0 &&
                    userInfData.expect.maxsalary != 0
                  "
                  >薪资要求：{{ userInfData.expect.minsalary / 1000 }}-{{
                    userInfData.expect.maxsalary / 1000
                  }}K</span
                >
                <span
                  v-if="
                    userInfData.expect.minsalary == 0 &&
                    userInfData.expect.maxsalary == 0
                  "
                  >薪资要求：面议</span
                >
              </div>
              <div class="xz-item">
                <span class="dian"></span>
                <span>工作性质：{{ userInfData.member.type_name }}</span>
              </div>
              <div class="xz-item">
                <span class="dian"></span>
                <span>期望行业：{{ userInfData.expect.hy_name }}</span>
              </div>
            </div>
          </div>
        </div>

        <!--        <div
            class="talents-detail-commen-view"
            v-show="userInfData.member.description"
        >
          <div class="commen-title-view">
            <span class="title-xian"></span>
            <span class="title-text">自我介绍</span>
          </div>
          <label>
            <div class="exp-detail-view">
              <div class="tt3">
                {{userInfData.member.description}}
              </div>
            </div>
          </label>
        </div>-->

        <div
          class="talents-detail-commen-view"
          v-show="userInfData.work.length > 0"
        >
          <div class="commen-title-view">
            <span class="title-xian"></span>
            <span class="title-text">工作经历</span>
          </div>
          <label v-for="work in userInfData.work" :key="work.id">
            <div class="exp-detail-view">
              <div class="tt1">
                {{ work.name }}
                <span>{{ work.sdate_text }} - {{ work.edate_text }}</span>
              </div>
              <div class="tt2">{{ work.title }}</div>
              <div class="tt3" v-show="work.content != ''">工作描述：
                <div>{{ work.content }}</div>
              </div>
            </div>
          </label>
        </div>

        <div
          class="talents-detail-commen-view"
          v-show="userInfData.resume_edus.length > 0"
        >
          <div class="commen-title-view">
            <span class="title-xian"></span>
            <span class="title-text">教育经历</span>
          </div>
          <label v-for="edu in userInfData.resume_edus" :key="edu.id">
            <div class="exp-detail-view">
              <div class="tt1">
                {{ edu.name }}
                <span>{{ edu.sdate_text }} - {{ edu.edate_text }}</span>
              </div>
              <div class="tt2">
                {{ edu.education_name }} | {{ edu.specialty }}
              </div>
              <div class="tt3" v-show="edu.content != ''">在校经历：
                <div>{{ edu.content }}</div>
              </div>
            </div>
          </label>
        </div>
        <!--        培训经历-->
        <div
          class="talents-detail-commen-view"
          v-show="userInfData.trainings.length > 0"
        >
          <div class="commen-title-view">
            <span class="title-xian"></span>
            <span class="title-text">培训经历</span>
          </div>
          <label v-for="edu in userInfData.trainings" :key="edu.id">
            <div class="exp-detail-view">
              <div class="tt1">
                {{ edu.name }}
                <span>{{ edu.sdate_text }} - {{ edu.edate_text }}</span>
              </div>
              <div class="tt3" v-show="edu.content != ''">
                培训描述：{{ edu.content }}
              </div>
            </div>
          </label>
        </div>
        <div
          class="talents-detail-commen-view"
          v-show="userInfData.cert.length > 0"
        >
          <div class="commen-title-view">
            <span class="title-xian"></span>
            <span class="title-text">技能证书</span>
          </div>

          <label v-for="cert in userInfData.cert" :key="cert.id">
            <div class="exp-detail-view">
              <div class="tt2">
                <span class="cer-dian"></span>{{ cert.name }}
                <span class="cer-time">{{ cert.longtime }}</span>
              </div>
            </div>
          </label>
        </div>

        <div
          class="talents-detail-commen-view"
          v-show="showList.length > 0 || videObj.video"
        >
          <div class="commen-title-view">
            <span class="title-xian"></span>
            <span class="title-text">我的作品</span>
          </div>

          <div class="zp-img-list-view">
            <div
              v-show="videObj.video"
              @click="isOpenVideo = true"
              class="el-image"
              style="
                width: 140px;
                height: 100px;
                border: #e6e6e6 solid 1px;
                margin: 0 12px 10px 0;
                cursor: pointer;
              "
            >
              <img
                src="@/assets/transfer_spplay.png"
                style="
                  width: 40px;
                  height: 40px;
                  position: absolute;
                  left: 50px;
                  top: 30px;
                "
              />
              <img :src="videObj.cover" style="width: 100%; height: 100%" />
            </div>
            <label v-for="(item, idx) in showList" :key="idx">
              <el-image
                style="
                  width: 140px;
                  height: 100px;
                  border: #e6e6e6 solid 1px;
                  margin: 0 12px 10px 0;
                "
                :src="item"
                :preview-src-list="showList"
              >
              </el-image>
            </label>
          </div>
        </div>
      </div>
      <div class="tj-talents-list-view">
        <img class="yhjl-view" src="@/assets/findjob_yh_jl.png" alt="" />

        <div class="talents-list-tjtal-view">
          <div class="tj-title-view">
            <img class="tj-title-img" src="../assets/index_jxgw.png" alt="" />
            <span class="tj-view">推荐人才</span>
          </div>
          <div
            class="tal-tj-list"
            v-for="item in recUserList"
            :key="item.id"
            @click.stop="openNewUser(item.uid)"
          >
            <div class="tal-tjitem">
              <div class="tal-tj-top-view">
                <span class="tal-tj-t1"
                  >{{ item.name }}
                  <img
                    v-show="item.sex_name === '男'"
                    class="rec-gender-img"
                    src="@/assets/gender_nan.png"
                    alt=""
                  />
                  <img
                    v-show="item.sex_name === '女'"
                    class="rec-gender-img"
                    src="@/assets/gender_nv.png"
                    alt=""
                  />
                </span>
                <span
                  style="
                    width: 200px;
                    font-size: 12px;
                    color: #757575;
                    text-align: end;
                  "
                  >{{ item.city_class }}</span
                >
              </div>
              <div class="age-edu-exp">
                <span>{{ item.age }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.edu_name }}</span>
                <el-divider direction="vertical"></el-divider>
                <span>{{ item.exp_name }}</span>
              </div>
              <div class="want-name">
                想找：<label v-for="(it, index) in item.job_class" :key="index"
                  >{{ it }}
                  <label
                    v-show="
                      item.job_class && index != item.job_class.length - 1
                    "
                    >/</label
                  ></label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <reportdialog
      :reportDialogShow="reportDialogShow"
      :dlTitle="'举报简历'"
      :rpUid="uid"
      @closeReportDialog="closeReportDialog"
    ></reportdialog>

    <el-dialog
      :title="payDialogTitle"
      :visible.sync="payDialogShow"
      class="vip-pay-dialog-css"
    >
      <div class="vip-type-view">
        <img class="vip-type-bg" src="@/assets/vip_pay_bg_img.png" alt="" />
        <div class="vip-type-text-view">
          <img class="lczx-img" src="@/assets/vip_pay_zx_img.png" alt="" />
          <div class="left-text-view">
            <div class="name-tt1">{{ payInf.name }}</div>
            <div class="time">{{ payInf.use }}</div>
          </div>
          <div class="right-text-view">
            <label>￥</label>{{ payInf.service_price }}
          </div>
        </div>
      </div>
      <div class="pay-type-view">
        <div class="paytype-title">支付方式</div>
        <div class="paytype">
          <div
            class="paytype-item"
            @click="changePayType('wxpay')"
            :class="payType == 'wxpay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_wx_img.png" alt="" />
            <span>微信支付</span>
          </div>
          <div
            class="paytype-item"
            @click="changePayType('alipay')"
            :class="payType == 'alipay' ? 'item-sl' : ''"
          >
            <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
            <span>支付宝支付</span>
          </div>
        </div>
      </div>
      <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
        <div class="wxpay-tt1">请使用<label>微信</label>扫描二维码完成支付</div>
        <div class="wxpay-tt2">
          如无法支付或二维码已过期请点击<label>刷新</label>
        </div>
        <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
        <div class="wxpay-tt2">
          购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
        </div>
      </div>
      <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toAliPayPage">去支付</el-button>
      </div>
    </el-dialog>
    <div class="video-open">
      <el-dialog
        :visible.sync="isOpenVideo"
        :show-close="false"
        :before-close="closeVideo"
      >
        <div style="height: 650px; display: flex; justify-content: center">
          <video
            controls
            style="max-height: 650px; min-width: 400px"
            ref="videoPlayer"
          >
            <source :src="videObj.video" type="video/mp4" />
            您的浏览器不支持 video 标签。
          </video>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getTalentsDetail,
  checkCanGetPhone,
  checkCanChat,
  checkIsLahe,
  postCollection,
  getTalentsList,
} from "@/api/commen/aboutTalentsApi.js";
import { submitOrder } from "@/api/commen/payApi.js";
import { getServiceList } from "@/api/compe/myComboApi.js";
import { userPcApi } from "@/api/commen/transferShop";
import reportdialog from "@/components/reportDialog.vue";

export default {
  components: {
    reportdialog,
  },
  data() {
    return {
      wxPayImgUrl: "",
      showList: [],
      videObj: {
        video: "",
        cover: "",
      },
      isOpenVideo: false,
      recUserList: [],
      uid: "",
      userAvatar: "",
      userInfData: {},
      reportDialogShow: false,
      payDialogShow: false,
      zzServiceList: "",
      payDialogTitle: "购买直聊卡",
      payType: "wxpay",
      payInf: {},
      shareEwmImg: "",
      isYl: false,
      isDownAgain: false,
      purchaseType: 0,
    };
  },
  created() {
    if (!localStorage.token && !this.$route.query.isAd) {
      this.$router.push("/login");
    } else {
      if (this.$route.query.isyl) {
        this.isYl = true;
      }
      this.uid = this.$route.query.id;
      this.getTalentsDetail(this.$route.query.id);
      getServiceList()
        .then((result) => {
          this.zzServiceList = result.data;
        })
        .catch(() => {});
      this.getRecTalentsList();
    }
  },
  methods: {
    openNewUser(uid) {
      this.uid = uid;
      this.getTalentsDetail(uid);
      window.scrollTo(0, 0);
    },
    closeVideo() {
      const video = this.$refs.videoPlayer;
      if (video) {
        video.pause();
        video.currentTime = 0; // 可选，将视频 currentTime 重置为 0
      }
      this.isOpenVideo = false;
    },
    getRecTalentsList() {
      let c_arr = {
        page: 1, // 页码
        provinceid: "all", // 省
        cityid: "all", // 市
        three_cityid: "all", // 区
        job_id: "all", // 职业, 例: 宠物医生
        search_key: "all", // 搜索内容
        edu: "all", // 教育经历
        exp: "all", // 工作经历
        type: "all", // 职位类型
        min_value: "all", // 期望薪资最小值
        max_value: "all", // 期望薪资最大值
        job_type_id: "all",
        sex: "all",
        is_precise: 1,
      };
      getTalentsList(c_arr)
        .then((result) => {
          console.log(result);
          this.recUserList = result.data.jobs;
          this.recUserList.forEach((res) => {
            if (res.city_class) {
              res.city_class = res.city_class.join(",");
            } else {
              res.city_class = "";
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 获取求职者详细信息 */
    getTalentsDetail(id) {
      getTalentsDetail({ uid: id })
        .then((result) => {
          this.userInfData = result.data;
          if (result.data.shows) {
            result.data.shows.forEach((im) => {
              if (im.coverurl) {
                this.videObj.video = im.picurl;
                this.videObj.cover = im.coverurl;
              } else {
                this.showList.push(im.picurl);
              }
            });
          }
        })
        .catch(() => {});
    },

    /* 查看联系方式 */
    lookTalentsPhone() {
      if (!localStorage.getItem("token")) {
        this.$router.push(`/login`);
        return;
      }
      let params = { uid: this.uid };
      if (this.isDownAgain) {
        params.is_ok = 1;
      }
      checkCanGetPhone(params)
        .then((result) => {
          console.log("检查能否查看联系方式", result);
          if (result.msg == "您的下载简历次数剩余不足,请前往充值") {
            this.$confirm("您的下载简历次数剩余不足,请前往充值?", "提示", {
              confirmButtonText: "立即购买",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.payInf = this.zzServiceList[1];
                this.payDialogTitle = "购买简历";
                this.purchaseType = "5";
                this.payDialogShowFun();
                // this.$router.push(`/myequitypage`);
              })
              .catch(() => {});
            return;
          }
          if (result.code == 102) {
            this.$confirm(
              "升级VIP，可以第一时间联系心仪的人哦～",
              "请升级VIP会员",
              {
                confirmButtonText: "立即升级",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
            return;
          }
          if (result.data.moblie != "") {
            this.$confirm(result.data.moblie, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {})
              .catch(() => {});
            this.getTalentsDetail(this.uid);
          } else {
            let date = new Date(result.data.time * 1000);
            let dateStr = date.getFullYear() + "-";
            let month = date.getMonth() + 1;
            month = month < 10 ? "0" + month : month;
            dateStr += month;
            let days = date.getDate();
            days = days < 10 ? "0" + days : days;
            dateStr = dateStr + "-" + days;
            // this.list[0].text = result.data.moblie;  //不知道干啥的 先注释掉
            this.time = dateStr;
            this.$confirm(
              `你在${dateStr}已查看过该简历，是否继续查看`,
              "提示",
              {
                confirmButtonText: "继续查看",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.isDownAgain = true;
                this.lookTalentsPhone();
              })
              .catch(() => {});
            // this.modalContenDowm = `你在${dateStr}已查看过该简历，是否继续查看`;
            // this.dowmShow = true;
          }
        })
        .catch(() => {});
    },

    /* 立即沟通 */
    toChat() {
      checkCanChat({ to_uid: this.uid })
        .then((result) => {
          console.log("查看能否治疗", result);
          if (result.code == 200) {
            // if (result.data.is_vip != 0 && result.data.is_can_chat == 0) {
            //   this.$confirm(
            //     "您的直聊权限已用完,赶紧购买直聊卡联系心仪的人吧!",
            //     "提示",
            //     {
            //       confirmButtonText: "立即购买",
            //       cancelButtonText: "取消",
            //       type: "warning",
            //     }
            //   )
            //     .then(() => {
            //       // this.$router.push(`/myequitypage`);
            //       this.payInf = this.zzServiceList[0];
            //       this.payDialogTitle = "购买直聊卡";
            //       this.payDialogShowFun();
            //     })
            //     .catch(() => {});
            // } else if (
            //   result.data.is_vip == 0 &&
            //   result.data.is_can_chat == 0
            // ) {
            //   this.$confirm(
            //     "升级VIP，可以第一时间联系心仪的人哦～",
            //     "请升级VIP会员",
            //     {
            //       confirmButtonText: "立即升级",
            //       cancelButtonText: "取消",
            //       type: "warning",
            //     }
            //   )
            //     .then(() => {
            //       this.$router.push(`/vippaypage`);
            //     })
            //     .catch(() => {});
            // } else {
            /* 判断拉黑 */
            checkIsLahe({ to_uid: this.uid })
              .then((result) => {
                console.log("是否拉黑", result);
                if (result.data.hmd == 1) {
                  this.$confirm(
                    "您已将对方拉黑，确认要给他发送消息吗？",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {})
                    .catch(() => {});
                } else {
                  if (result.data.to_hmd == 1) {
                    this.$confirm(
                      "对方已将你拉黑，您不能发送消息给TA!",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.$router.push(`/chatpage?chatid=${this.uid}`);
                    // 聊天室进来的返回聊天室
                    // let pages = getCurrentPages(); //页面对象
                    // let prevpage = pages[pages.length - 2]; //上一个页面对象
                    // console.log(prevpage);
                    // if (prevpage) {
                    //   if (prevpage.route == "packageA/pages/news/chat") {
                    //     uni.navigateBack();
                    //     return;
                    //   }
                    // }
                    // // 分享和首页进来的都跳到直聊
                    // uni.navigateTo({
                    //   url: "/pagesA/chat/chat?uid=" + this.uid,
                    // });
                  }
                }
              })
              .catch(() => {});
            // }
          } else if (result.code == 201) {
            this.$confirm(
              "您的直聊权限已用完,赶紧购买直聊卡联系心仪的人吧!",
              "提示",
              {
                confirmButtonText: "立即购买",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                // this.$router.push(`/myequitypage`);
                this.payInf = this.zzServiceList[0];
                this.payDialogTitle = "购买直聊卡";
                this.purchaseType = "5";
                this.payDialogShowFun();
              })
              .catch(() => {});
          } else if (result.code == 202) {
            this.$confirm(result.msg, "未购买开放该职位", {
              confirmButtonText: "立即升级",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 203) {
            this.$confirm(
              "请升级VIP会员或购买相对应的职位开放权限，才可以联系TA 。",
              "请升级VIP会员",
              {
                confirmButtonText: "立即升级",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.$router.push(`/vippaypage`);
              })
              .catch(() => {});
          } else if (result.code == 204) {
            this.$confirm(
              "您今天的直聊权益已用完，购买当日畅聊权益，今日不限聊！",
              "当日畅聊权益",
              {
                confirmButtonText: "立即购买",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.payInf = {
                  name: "当日畅聊卡",
                  use: "当日",
                  service_price: "6",
                  detail_id:0
                };
                this.payDialogTitle = "当日畅聊";
                this.purchaseType = "33";
                this.payDialogShowFun();
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },

    closeReportDialog() {
      this.reportDialogShow = false;
    },

    /* 收藏 */
    postCollection() {
      postCollection({
        uid: this.uid,
      })
        .then((result) => {
          console.log("收藏简历", result);
          if (result.code == 200) {
            this.$message({
              message: result.msg,
              type: "success",
              center: true,
            });
            this.getTalentsDetail(this.uid);
          }
        })
        .catch(() => {});
    },
    /* 获取分享二维码 */
    getShareEwm() {
      userPcApi({ type: "createQrcode", id: this.uid, exeType: "resume" })
        .then((result) => {
          if (result.code == 200) {
            this.shareEwmImg = result.data.img;
          }
        })
        .catch(() => {});
    },

    payDialogShowFun() {
      submitOrder({
        type: this.purchaseType,
        rating_id: this.payInf.detail_id,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          this.wxPayImgUrl = result.data.pay_img;
          this.payDialogShow = true;
        })
        .catch(() => {});
    },

    changePayType(type) {
      this.payType = type;
      submitOrder({
        type: this.purchaseType,
        rating_id: this.payInf.detail_id,
        pay_type: this.payType,
      })
        .then((result) => {
          console.log("提交订单", result);
          if (type == "wxpay") {
            this.wxPayImgUrl = result.data.pay_img;
          } else {
            this.aliNatoUrl = result.data.url;
          }
        })
        .catch(() => {});
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },
    toEditResPage() {
      this.$router.push(`/useredtresume`);
    },
  },
};
</script>

<style>
.talents-detail-page .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}

.talents-detail-page {
  /* padding: 0 455px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.talents-list-tjtal-view {
  width: 355px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 25px 24px;
}

.talents-list-tjtal-view .tj-title-view {
  display: flex;
  align-items: center;
}

.talents-list-tjtal-view .tj-title-view .tj-title-img {
  width: 26px;
  height: 26px;
  margin-right: 10px;
}

.talents-list-tjtal-view .tj-title-view .tj-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.talents-list-tjtal-view .tal-tj-list .tal-tjitem {
  border-bottom: 1px solid #f5f6fa;
  padding: 9px 0;
  margin-top: 17px;
}

.talents-list-tjtal-view .tal-tj-list .tal-tjitem .tal-tj-top-view {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.talents-list-tjtal-view .tal-tj-list .tal-tjitem .tal-tj-top-view img {
  width: 18px;
  height: 18px;
}

.talents-list-tjtal-view .tal-tj-list .tal-tjitem .age-edu-exp {
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 8px;
}

.talents-list-tjtal-view .tal-tj-list .tal-tjitem .want-name {
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  line-height: 22px;
  margin-top: 8px;
}

.talents-list-tjtal-view .tal-tj-list .tal-tjitem .want-name label {
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
  line-height: 14px;
}

.talents-detailinf-tjlist-view {
  display: flex;
  margin-top: 40px;
}

.talents-detailinf-tjlist-view .detailinf-lefit-view {
  /* margin-left: 220px; */
}

.talents-detail-commen-view {
  width: 962px;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 30px 36px;
  margin-bottom: 30px;
}

.talents-detail-commen-view .commen-title-view {
  display: flex;
  align-items: center;
}

.talents-detail-commen-view .commen-title-view .title-xian {
  display: block;
  width: 4px;
  height: 20px;
  border-radius: 5px;
  background-color: #fe6002;
}

.talents-detail-commen-view .commen-title-view .title-text {
  font-size: 24px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
  margin-left: 16px;
}

.qzyx-detail-view {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}

.qzyx-detail-view .yx-left-view .yx-item {
  display: flex;
  align-items: center;
  font-size: 18px;

  font-weight: 400;
  color: #303030;
  line-height: 28px;
  margin-top: 12px;
}

.qzyx-detail-view .yx-left-view .yx-item img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.qzyx-detail-view .el-divider {
  height: 120px;
}

.qzyx-detail-view .yx-right-view {
  width: 32%;
}

.qzyx-detail-view .yx-right-view .xz-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #303030;
  line-height: 24px;
  margin-top: 18px;
}

.qzyx-detail-view .yx-right-view .xz-item .dian {
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: #d9d9d9;
  border-radius: 50%;
  margin-right: 8px;
}

.exp-detail-view {
  margin-top: 28px;
}

.exp-detail-view .tt1 {
  font-size: 22px;
  /* font-weight: 600; */
  color: #191919;
  line-height: 28px;
}

.exp-detail-view .tt1 span {
  font-size: 16px;
  /* font-weight: 600; */
  color: #303030;
  line-height: 19px;
  margin-left: 30px;
}

.exp-detail-view .tt2 {
  font-size: 18px;

  /* font-weight: 600; */
  color: #191919;
  line-height: 28px;
  margin-top: 10px;
}

.exp-detail-view .tt2 .cer-dian {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #d9d9d9;
  border-radius: 50%;
  margin-right: 18px;
}

.exp-detail-view .tt2 .cer-time {
  font-size: 18px;

  font-weight: 600;
  color: #303030;
  line-height: 28px;
  margin-left: 32px;
}

.exp-detail-view .tt3 {
  white-space: pre-wrap;
  font-size: 18px;

  font-weight: 400;
  color: #303030;
  line-height: 30px;
  margin-top: 10px;
}

.talents-baseinf-view {
  width: 1480px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
}

.talents-baseinf-view .talen-lefit-view .avatar-name-sex-age {
  display: flex;
  align-items: center;
}

.talents-baseinf-view .talen-lefit-view .avatar-name-sex-age .avatar-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view {
  margin-left: 24px;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .name-sex-stu {
  position: relative;
  display: flex;
  align-items: center;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .name-sex-stu
  .name {
  font-size: 36px;

  font-weight: 600;
  color: #191919;
  line-height: 42px;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .name-sex-stu
  .sex-img {
  width: 26px;
  height: 26px;
  position: absolute;
  left: -50px;
  top: 70px;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .name-sex-stu
  .rz-status-img {
  width: 22px;
  height: 22px;
  margin-left: 30px;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .name-sex-stu
  .sta-text {
  font-size: 14px;

  font-weight: 400;
  color: #fe6002;
  line-height: 16px;
  margin-left: 2px;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .age-edu-exp-add {
  display: flex;
  font-size: 18px;

  font-weight: 400;
  color: #3d3d3d;
  line-height: 24px;
  margin-top: 26px;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .age-edu-exp-add
  .item {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.talents-baseinf-view
  .talen-lefit-view
  .avatar-name-sex-age
  .name-sex-age-view
  .age-edu-exp-add
  .item
  .it-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.talents-baseinf-view .talen-lefit-view .user-tag-view {
  margin-top: 24px;
  max-width: 858px;
}

.talents-baseinf-view .talen-lefit-view .user-tag-view .tag-item {
  display: inline-block;
  padding: 6px 16px;
  background-color: #f5f6fa;
  border-radius: 8px;
  margin-right: 14px;
  font-size: 18px;

  font-weight: 400;
  color: #303030;
  line-height: 24px;
  margin-bottom: 10px;
}

.talents-baseinf-view .talen-lefit-view .up-look-jb-view {
  display: flex;
  align-items: center;
  margin-top: 25px;
}

.talents-baseinf-view .talen-lefit-view .up-look-jb-view .item {
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
  margin-right: 36px;
}

.talents-baseinf-view .talen-lefit-view .up-look-jb-view .item img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.talents-baseinf-view .right-btn-share-col-view {
  text-align: right;
}

.talents-baseinf-view
  .right-btn-share-col-view
  .link-btn-chat-view
  .el-button--primary {
  width: 340px;
  height: 62px;
  font-size: 24px;

  font-weight: 500;
}

.talents-baseinf-view .right-btn-share-col-view .link-btn-chat-view .is-plain {
  width: 216px;
  height: 62px;
  font-size: 24px;

  font-weight: 600;
}

.talents-baseinf-view .right-btn-share-col-view .share-colle-view {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 40px;
}

.talents-baseinf-view .right-btn-share-col-view .share-colle-view .item {
  font-size: 16px;

  font-weight: 400;
  color: #fe6002;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.talents-baseinf-view .right-btn-share-col-view .share-colle-view .item img {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}

.share-ewm-img {
  width: 100%;
  text-align: center;
}

.share-ewm-img img {
  width: 200px;
  height: 200px;
}

.talents-detail-page .tj-talents-list-view .yhjl-view {
  width: 403px;
  height: 200px;
}

.tj-talents-list-view {
  margin-left: 43px;
}

.talents-detail-page .zp-img-list-view {
  margin-top: 20px;
}

.talents-detail-page .video-open .el-dialog {
  width: 100px;
  background-color: transparent;
}

.talents-detail-page .video-open .el-dialog .el-dialog__header {
  display: none;
}

.talents-detail-page .tal-tj-list {
  border-radius: 5px;
  cursor: pointer;
}

.talents-detail-page .tal-tj-list:hover {
  background-color: #fff5f0;
}

.talents-detail-page .user-tag-view .user-tag-description {
  font-size: 18px;
  white-space: pre-line;
  font-weight: 400;
  color: #303030;
  line-height: 30px;
  margin: 15px 0 15px 0;
}
</style>