<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-10 15:14:34
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-14 13:45:08
 * @FilePath: /lcwpcvue/src/components/comUser/jobManageItem.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="jobmanage-item-page">
    <div
      class="job-manage-item-page"
      :class="item.job_id == isHover ? 'hover-css' : ''"
      @mouseover="hadlerMouseOver(item.job_id)"
      @mouseout="hadlerMoseOut"
      v-for="item in joblist"
      @click="toYlJob(item)"
      :key="item.id"
    >
      <div class="jobma-left-view">
        <div class="job-titel">
          {{ item.name }}
          <span
            v-show="item.xsdate != '' && item.xsdate != 0"
            class="job-tag zd-tag"
            >置顶</span
          >
          <span v-show="item.rec != '' && item.rec != 0" class="job-tag tj-tag"
            >推荐</span
          >
          <span
            v-show="item.urgent != '' && item.urgent != 0"
            class="job-tag jzz-tag"
            >急聘</span
          >

          <span v-show="item.is_part != 0" class="job-tag jz-tag">兼职</span>
        </div>
        <div class="proc-exp-xz">
          <span>{{ item.province_name }}</span>
          <el-divider direction="vertical"></el-divider>
          <span>{{ item.exp_name }}</span>
          <el-divider direction="vertical"></el-divider>
          <span v-show="item.is_part === '1'">
            <span>{{ item.salary_str }}</span>
          </span>
          <span v-show="item.is_part !== '1'">
            <span v-show="item.salary_type != 1"
              >{{ item.minsalary / 1000 }}-{{ item.maxsalary / 1000 }}K</span
            >
            <span v-show="item.salary_type == 1">面议</span>
          </span>
        </div>
        <div class="bg-chat-rec-num-view">
          <span>曝光{{ item.jobhits }}次</span>
          <span>聊天{{ item.chat_num }}人</span>
          <span>收到简历{{ item.snum }}份</span>
        </div>

        <div class="rec-titme-text" @click.stop="showRefHisDialog(item.job_id)">
          {{ item.time }} 刷新 <i class="el-icon-arrow-right"></i>
        </div>
        <!-- <div class="rec-titme-text" @click="showRefHisDialog(item.job_id)">
          {{ item.time }} 刷新 <i class="el-icon-arrow-right"></i>
        </div> -->
      </div>
      <!-- <div class="jobma-min-view">
        <div class="num-inf-item">
          <div class="tt1">{{ item.jobhits }}</div>
          <div class="tt2">曝光</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="num-inf-item">
          <div class="tt1">{{ item.chat_num }}</div>
          <div class="tt2">沟通过</div>
        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="num-inf-item">
          <div class="tt1">{{ item.snum }}</div>
          <div class="tt2">收到简历</div>
        </div>
      </div> -->

      <div class="jobma-right-view">
        <div class="jobma-top-act-view">
          <div class="jobma-min-dow-status-view">
            <label v-if="item.state == 3">
              <div class="stutas-text2" v-show="item.state == 3">
                审核未通过
              </div>
            </label>
            <label v-else>
              <label v-if="item.status == 0">
                <div
                  class="stutas-text"
                  style="color: #fe6002; font-weight: 500"
                  v-if="item.is_pay == ''"
                >
                  待开放
                </div>
                <div
                  class="stutas-text"
                  style="color: #fe6002; font-weight: 500"
                  v-if="item.is_pay == 1"
                >
                  已开放
                </div>
              </label>
              <label v-else>
                <div class="stutas-text">已关闭</div>
              </label>
            </label>
          </div>

          <!-- <el-button @click="refrMyJob('manual', item.job_id)">刷新</el-button>
          <el-button>分享</el-button>
          <el-button @click="toYlJob(item.job_id)">预览</el-button>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <i slot="reference" class="el-icon-more ic-cl"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="{ type: 'colse', id: item.job_id }"
                >关闭职位</el-dropdown-item
              >
              <el-dropdown-item :command="{ type: 'del', id: item.job_id }"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown> -->
        </div>
        <div
          class="jobma-bot-act-view"
          v-if="item.state != 3 && item.status == 0"
        >
          <el-button
            v-if="item.is_pay == ''"
            type="primary"
            plain
            @click.stop="onjob(item.job_id, item.state,item.name)"
            >开放职位</el-button
          >
          <el-button
            plain
            @click.stop="getJobRatingList('integral_job_top', item.job_id)"
          >
            <img src="@/assets/com_jobman_zd.png" alt="" />
            置顶急聘</el-button
          >
          <el-button
            v-show="item.auto_refresh == -1"
            plain
            @click.stop="getJobRatingList('job_auto', item.job_id)"
          >
            <img src="@/assets/com_jobman_zdsx1.png" alt="" />
            自动刷新</el-button
          >
          <el-button
            v-show="item.auto_refresh > -1"
            plain
            type="primary"
            @click.stop="stopAoutRefresh(item.job_id, item.auto_refresh)"
          >
            <img src="@/assets/com_jobman_zdsx1.png" alt="" />
            自动刷新中</el-button
          >
          <el-button plain @click.stop="refreshwork(item.name, item.job_id)">
            <img src="@/assets/com_jobman_sx.png" alt="" />
            刷新职位</el-button
          >
          <div style="width: 55px; height: 30px; z-index: 999" @click.stop="">
            <el-dropdown style="margin-left: 30px" @command="handleCommandwa">
              <span class="el-dropdown-link">
                <i slot="reference" class="el-icon-more ic-cl"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ type: 'bj', item: item }"
                  >编辑</el-dropdown-item
                >
                <el-dropdown-item :command="{ type: 'colse', item: item }"
                  >关闭</el-dropdown-item
                >
                <el-dropdown-item :command="{ type: 'del', item: item }"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <!-- <el-button
            type="primary"
            plain
            round
            @click="getJobRatingList('integral_job_top', item.job_id)"
            >置顶</el-button
          >
          <el-button
            type="primary"
            plain
            round
            @click="getJobRatingList('com_urgent', item.job_id)"
            >急聘</el-button
          >
          <el-button
            type="primary"
            plain
            round
            @click="getJobRatingList('com_recjob', item.job_id)"
            >推荐</el-button
          >
          <el-button type="primary" plain round>高薪职位</el-button>
          <el-button
            type="primary"
            plain
            round
            @click="getJobRatingList('job_auto', item.job_id)"
            >自动刷新</el-button
          > -->
        </div>

        <div class="jobma-bbot-btn" v-if="item.state != 3 && item.status != 0">
          <el-button
            type="primary"
            plain
            @click.stop="onjob(item.job_id, item.state,item.name)"
            >开放职位</el-button
          >
          <el-button
            type="primary"
            plain
            @click.stop="toEdtiPage(item.is_part, item.job_id)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            plain
            @click.stop="delJob(item.job_id, item.is_part)"
            >删除</el-button
          >
          <!-- <span class="jobma-status-text">已下架</span> -->
        </div>

        <div class="jobma-bbot-btn" v-show="item.state == 3 && item.state == 3">
          <el-button
            type="primary"
            plain
            @click.stop="toEdtiPage(item.is_part, item.job_id)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            plain
            @click.stop="delJob(item.job_id, item.is_part)"
            >删除</el-button
          >
        </div>
      </div>
      <!-- <div class="jobma-right-view" v-show="listType == 'notPass'">
        <div class="jobma-bbot-btn">
          <el-button
            type="primary"
            plain
            @click.stop="toEdtiPage(item.is_part, item.job_id)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            plain
            @click.stop="delJob(item.job_id, item.is_part)"
            >删除</el-button
          >
        </div>
      </div> -->
      <!-- <div class="jobma-right-view" v-show="listType == 'down'">
        <div class="jobma-right-top-time-view">{{ item.time }} 发布</div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import { refrMyJob } from "@/api/compe/jobsApi.js";
import { delMyFullJob, delMyPartTimeJob } from "@/api/compe/jobsApi.js";
export default {
  name: "jobmanageitempage",
  props: {
    joblist: {
      type: Array,
    },
    listType: {
      type: String,
    },
  },
  data() {
    return {
      isHover: 0,
    };
  },
  methods: {
    hadlerMouseOver(id) {
      // console.log("悬停");
      this.isHover = id;
    },
    hadlerMoseOut() {
      this.isHover = 0;
    },

    /* 刷新职位 */
    refrMyJob(type, id) {
      this.$emit("refrMyJob", type, id);
    },

    sdRecJob(id) {
      console.log(id);
    },

    handleCommand(e) {
      console.log(e);
      this.$emit("colseAndDelMyJob", e.type, e.id);
    },
    showRefHisDialog(id) {
      this.$emit("getRefrHistoyList", id);
    },

    onjob(id, state,name) {
      this.$emit("onFullJobInMarange", id, state,name);
    },
    getJobRatingList(type, id) {
      this.$emit("getJobPriceList", type, id);
    },
    stopAoutRefresh(id, num) {
      this.$emit("stopAoutRefresh", id, num);
    },

    refreshwork(name, id) {
      this.$emit("refreshwork", name, id);
    },

    handleCommandwa(e) {
      console.log(e);
      if (e.type == "del") {
        this.$emit("colseAndDelMyJob", e.type, e.item.job_id, e.item.is_part);
      } else if (e.type == "colse") {
        this.$emit("colseAndDelMyJob", e.type, e.item.job_id, e.item.is_part);
      } else {
        this.$router.push(
          `/releasejob?id=${e.item.job_id}&ispart=${e.item.is_part}`
        );
      }
    },
    toYlJob(item) {
      if (item.is_part === "1") {
        this.$router.push(`/parttimejobdetail?id=${item.job_id}&isyl=1`);
      } else {
        if (item.status == 1) {
          if (item.state == 3) {
            this.$router.push(`/jobinfopage?id=${item.job_id}&isyl=${5}&state=${item.state}`);
          } else {
            this.$router.push(`/jobinfopage?id=${item.job_id}&isyl=${6}&state=${item.state}`);
          }
        } else {
          if (item.state == 3) {
            this.$router.push(`/jobinfopage?id=${item.job_id}&isyl=${5}&state=${item.state}`);
          } else {
            console.log("--------", item.is_pay);
            if (item.is_pay == "") {
              this.$router.push(`/jobinfopage?id=${item.job_id}&isyl=${4}&state=${item.state}`);
            } else {
              this.$router.push(`/jobinfopage?id=${item.job_id}&isyl=${3}&state=${item.state}`);
            }
          }
        }
      }
    },
    toEdtiPage(ispart, id) {
      this.$router.push(`/releasejob?id=${id}&ispart=${ispart}`);
    },
    delJob(id, isPart) {
      this.$confirm("确定要删除该职位吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (isPart == 1) {
            delMyPartTimeJob({
              id: id,
            })
              .then((result) => {
                console.log("删除职位", result);
                if (result.code == 200) {
                  location.reload();
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  // this.$router.go(-1);
                }
              })
              .catch(() => {});
          } else {
            delMyFullJob({ id: id })
              .then((result) => {
                console.log("删除职位", result);
                location.reload();
                if (result.code == 200) {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                  // this.$router.go(-1);
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
.colse-act-view {
  font-size: 14px;

  font-weight: 400;
  color: #191919;
  line-height: 16px;
}

.colse-act-view .act-item {
  padding: 5px 20px;
}

.job-manage-item-page {
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  padding: 24px 24px 12px 24px;
}
.jobmanage-item-page .hover-css {
  border: 1px solid #fe6002;
  background-color: #fff5f0;
}
.job-manage-item-page .jobma-left-view .job-titel {
  font-size: 24px;
  font-weight: 600;
  color: #191919;
  display: flex;
  align-items: center;
}

.job-manage-item-page .jobma-left-view .job-tag {
  display: inline-block;
  padding: 3px 5px;
  /*字体样式*/
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  text-align: center;
  margin-left: 10px;
  white-space: nowrap;
}
.job-manage-item-page .jobma-left-view .zd-tag {
  color: #3291e1;
  border: 1px solid #3291e1;
}

.job-manage-item-page .jobma-left-view .jz-tag {
  color: #54eaab;
  border: 1px solid #54eaab;
}
.job-manage-item-page .jobma-left-view .jzz-tag {
  color: #f34b37;
  border: 1px solid #f34b37;
}

.job-manage-item-page .jobma-left-view .tj-tag {
  color: #ff8136;
  border: 1px solid #ff8136;
}

.job-manage-item-page .jobma-left-view .proc-exp-xz {
  font-size: 16px;

  font-weight: 400;
  color: #3d3d3d;
  line-height: 19px;
  margin-top: 16px;
}

.job-manage-item-page .jobma-left-view .bg-chat-rec-num-view {
  font-weight: 400;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 19px;
  margin-top: 16px;
  padding-bottom: 10px;
}
.job-manage-item-page .jobma-left-view .bg-chat-rec-num-view span {
  margin-right: 32px;
}

.job-manage-item-page .jobma-min-view {
  display: flex;
  align-items: center;
}

.job-manage-item-page .jobma-min-view .el-divider--vertical {
  height: 60px;
  margin: 0 40px;
}
.job-manage-item-page .jobma-min-view .num-inf-item {
  text-align: center;
}

.job-manage-item-page .jobma-min-view .num-inf-item .tt1 {
  font-size: 24px;

  font-weight: 500;
  color: #303030;
  line-height: 28px;
}

.job-manage-item-page .jobma-min-view .num-inf-item .tt2 {
  font-size: 14px;

  font-weight: 400;
  color: #666666;
  line-height: 16px;
  margin-top: 8px;
}
.jobma-min-dow-status-view {
  /* height: 100px; */
  font-size: 16px;
  color: #b1b1b1;
  display: flex;
  align-items: flex-end;
}
.jobma-min-dow-status-view .stutas-text2 {
  color: #ff8136;
}

.job-manage-item-page .jobma-right-view {
  text-align: right;
}
.job-manage-item-page .jobma-right-view .jobma-top-act-view {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.job-manage-item-page .jobma-left-view .rec-titme-text {
  font-weight: 400;
  font-size: 16px;
  color: #b1b1b1;
  line-height: 19px;
}

.job-manage-item-page
  .jobma-right-view
  .jobma-top-act-view
  .el-button--default {
  width: 72px;
  height: 32px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-manage-item-page .jobma-right-view .jobma-top-act-view .ic-cl {
  font-size: 20px;
  margin-left: 35px;
  color: #666666;
}

.job-manage-item-page .jobma-right-view .jobma-bot-act-view {
  margin-top: 46px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.job-manage-item-page .jobma-right-view .jobma-bot-act-view .is-round {
  height: 26px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.jobma-right-view .jobma-right-top-time-view {
  font-size: 16px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 19px;
}

.jobma-right-view .jobma-bbot-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 28px;
}

.jobma-right-view .jobma-bbot-btn .is-plain {
  width: 72px;
  height: 32px;
  font-size: 14px;

  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-manage-item-page .jobma-right-view .jobma-bot-act-view .is-plain {
  width: 112px;
  height: 34px;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-manage-item-page .jobma-right-view .jobma-bot-act-view .is-plain img {
  width: 18px;
  height: 18px;
}

.jobma-right-view .jobma-bbot-btn .jobma-status-text {
  font-size: 16px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 19px;
  margin-left: 28px;
}
.jobma-right-view .jobma-bbot-btn .jobma-status-text1 {
  font-size: 16px;

  font-weight: 400;
  color: #fe6002;
  line-height: 19px;
  margin-right: 40px;
}

.jobma-right-view .jobma-bbot-btn .ic-cl {
  font-size: 20px;
  margin-left: 35px;
  color: #fe6002;
}
.job-manage-item-page .el-dropdown-link {
  font-size: 30px;
}
</style>