<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-05-25 14:35:00
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-03-05 16:09:02
 * @FilePath: /lcwpcvue/src/components/choseCity.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="chose-city">
    <el-dialog
      title="请选择城市"
      :visible="dialogVisible2"
      width="1003px"
      @close="handleClose"
    >
      <!-- <div class="citys-ychose">
        <span class="yx-view">已选择</span>
        <span class="tsmin">（最多可以选择5项）</span>
        <label v-for="(item, index) in sladdreArr" :key="item.id">
          <div class="yxct">
            <span>{{ item.name }}</span>
            <i @click="delSlArr(index)" class="el-icon-close"></i>
          </div>
        </label>
      </div> -->

      <div class="citys-view">
        <div class="prce">
          <label
            v-for="item in provinceList"
            :key="item.id"
            @click="changProvinceId(item)"
          >
            <div class="item" :class="provinceid == item.id ? 'itsl' : ''">
              {{ item.name }}
            </div>
          </label>
          <!-- <div class="item itsl">上海</div>
          <div class="item">天津</div>
          <div class="item">重庆</div> -->
        </div>
        <div class="citys">
          <div
            class="item"
            :class="cityId == 'all' ? 'itsl' : ''"
            @click="changeCitysId({ id: 'all' })"
          >
            全部
          </div>
          <label v-for="item in cityList" :key="item.id">
            <div
              class="item"
              :class="cityId == item.id ? 'itsl' : ''"
              @click="changeCitysId(item)"
            >
              {{ item.name }}
            </div>
          </label>
          <!-- <div class="item itsl">大兴</div>
          <div class="item">海淀</div> -->
        </div>
        <div class="ara">
          <div
            class="last"
            :class="areaId == 'all' ? 'arasl' : ''"
            @click="choseArea({ id: 'all' })"
          >
            全部
          </div>
          <label v-for="item in areaList" :key="item.id">
            <div
              class="last"
              :class="areaId == item.id ? 'arasl' : ''"
              @click="choseArea(item)"
            >
              {{ item.name }}
            </div>
          </label>

          <el-button type="primary" @click="saveJobAddr">确定</el-button>
          <!-- <div class="last arasl">南城区</div>
          <div class="last">南城区</div>
          <div class="last">南城区</div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getProvinceList,
  getCityList,
  getAreaList,
} from "@/api/commen/register";
import { Message } from "element-ui";
export default {
  props: {
    dialogVisible2: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      provinceList: [],
      cityList: [],
      areaList: [],
      provinceid: 0, // 省id
      cityId: "all", // 市id
      areaId: "all",
      sladdreArr: [],
      chPrIt: {},
      chCityIt: {},
    };
  },
  created() {
    this.getProvinceList();
  },
  methods: {
    //获取省份列表
    getProvinceList() {
      // this.listLoading = true
      getProvinceList()
        .then((response) => {
          console.log("获取列表。。", response);
          // response.data.forEach((element) => {
          //   console.log(Date.parse(element.createdAt))
          //   element.addtime = parseTime(Date.parse(element.createdAt))
          // })
          this.provinceList = response.data.province;
          this.provinceid = response.data.province[0].id;
          this.chPrIt = response.data.province[0];
          this.getCityList(response.data.province[0].id);
          // this.listLoading = false
        })
        .catch((error) => {
          console.log(error);
          // this.listLoading = false
        });
    },
    getCityList(id) {
      getCityList(id)
        .then((response) => {
          console.log("获取城市。。", response);
          // response.data.forEach((element) => {
          //   console.log(Date.parse(element.createdAt))
          //   element.addtime = parseTime(Date.parse(element.createdAt))
          // })
          this.cityList = response.data.province;
          // this.provinceid = response.data.province[0].id;
          //
          // this.listLoading = false
        })
        .catch((error) => {
          console.log(error);
          // this.listLoading = false
        });
    },
    getAreaList(id) {
      getAreaList(id)
        .then((result) => {
          console.log("获取区", result);
          this.areaList = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changProvinceId(item) {
      this.provinceid = item.id;
      this.cityId = "all";
      this.areaList = [];
      this.chPrIt = item;
      this.chCityIt = {};
      this.getCityList(item.id);
    },

    changeCitysId(item) {
      this.cityId = item.id;

      if (item.id == "all") {
        if (this.sladdreArr.length < 5) {
          console.log(this.chPrIt);
          this.sladdreArr.push(this.chPrIt);
        } else {
          Message({
            message: "最多只能选五项",
            type: "error",
            duration: 3 * 1000,
            showClose: true,
            offset: 100,
          });
        }
        return false;
      }
      this.chCityIt = item;
      this.getAreaList(item.id);
    },
    choseArea(item) {
      this.areaId = item.id;
      if (item.id == "all") {
        this.$emit("saveJobAddr", this.chCityIt);

        // if (this.sladdreArr.length < 5) {
        //   this.sladdreArr.push(this.chCityIt);
        // } else {
        //   Message({
        //     message: "最多只能选五项",
        //     type: "error",
        //     duration: 3 * 1000,
        //     showClose: true,
        //     offset: 100,
        //   });
        // }
        // return false;
      } else {
        this.$emit("saveJobAddr", item);
      }

      // if (this.sladdreArr.length < 5) {
      //   this.sladdreArr.push(item);
      // } else {
      //   Message({
      //     message: "最多只能选五项",
      //     type: "error",
      //     duration: 3 * 1000,
      //     showClose: true,
      //     offset: 100,
      //   });
      // }
    },

    delSlArr(index) {
      this.sladdreArr.splice(index, 1);
    },

    saveJobAddr() {
      console.log('this.sladdreArr:',this.sladdreArr)
      // this.$emit("saveJobAddr", this.sladdreArr);
      let choose = {};
      if (Object.keys(this.chCityIt).length > 0){
        choose = this.chCityIt;
      }else if (Object.keys(this.chPrIt).length > 0){
        choose = this.chPrIt;
      }else {
        return this.$message.error('请选择')
      }
      this.$emit("saveJobAddr", choose);
    },

    handleClose() {
      this.$emit("closeDilog2");
    },
  },
};
</script>

<style scoped>
.chose-city .el-dialog__wrapper .el-dialog {
  border-radius: 15px;
  height: 639px;
}

.el-dialog__wrapper .el-dialog .el-dialog__title {
  font-size: 26px;
  
  font-weight: 600;
  color: #222222;
}
.el-dialog__wrapper .el-dialog__body {
  padding: 0px 0;
}
.citys-ychose {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dddddd;
  padding: 0 20px;
  padding-bottom: 18px;
}

.citys-ychose .yx-view {
  font-size: 20px;
  color: #222222;
  
  font-weight: 600;
}

.citys-ychose .tsmin {
  font-size: 12px;
  color: #939597;
  font-weight: 400;
}
.citys-ychose .yxct {
  width: 80px;
  height: 26px;
  background-color: #ff8136;
  border-radius: 2px;
  font-size: 14px;
  
  font-weight: 400;
  color: #fffdfc;
  text-align: center;
  line-height: 26px;
  margin-left: 12px;
}
.citys-ychose .yxct i {
  margin-left: 12px;
}

.citys-view {
  display: flex;
}

.citys-view .prce,
.citys-view .citys {
  /* width: 80px; */
  height: 534px;
  font-size: 18px;
  
  font-weight: 400;
  color: #222222;
  border-right: 2px solid #e7e8eb;
  overflow: scroll;
}

.citys-view .item {
  width: 80px;
  padding: 14px 40px;
  cursor: pointer;
}
.citys-view .item:hover,.citys-view .last:hover{
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
}
.citys-view .itsl {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
}
.citys-view .ara {
  margin-left: 33px;
  max-width: 643px;
  height: 534px;
  overflow: scroll;
}

.citys-view .last {
  display: inline-block;
  width: 146px;
  height: 38px;
  background: #f5f6fa;
  border-radius: 1px;
  text-align: center;
  line-height: 38px;
  margin-right: 35px;
  margin-top: 24px;
  margin-bottom: 9px;
  cursor: pointer;
}

.citys-view .arasl {
  background-color: rgba(255, 129, 54, 0.15);
  color: #fe6002;
  border: 1px solid #fe6002;
}

.citys-view .el-button {
  width: 200px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}
</style>